import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import styled from "styled-components"
import {BgImageOverlay, BgImageContainer} from "../styled/container"
import { device, size } from '../styled/devices';

import ImageLeft from "../components/imageleft"
import CTA from "../components/cta"
import Seo from "../components/seo"
import ImageRight from "../components/imageright"
import LargeImage from "../components/largeimage"
import Card from "../components/card"
import AnfrageForm from "../components/anfrageformular"
import {Container, RowContainer, RowContainerFluid, ContainerFluid} from "../styled/container"
import { window, document, exists } from 'browser-monads';
import Loading from "../components/loading"
import Bus from "../components/bus"
import Team from "../components/team"


const LandingPage = ({data}) => {
    data = data.wpPost;
    console.log("Landing?", data)

    const style = {
      backgroundImage: 'url(' + data.featuredImage.node.sourceUrl + ')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'

  }


  const sections = JSON.parse(data.contentSections)

  let cards = [];
  let cardsAdded = false;
  let cardStyle = "dark";
  for (let i = 0; i < sections.length; i++) {
      if (sections[i].sectionLayout === 'card') {
          if (cardStyle == 'dark' && cardStyle != sections[i].sectionStyle) {
              cardStyle = sections[i].sectionStyle;
          }
             cards.push(sections[i])
          }
      }
      
      
  const cardFunc = () => {
      console.log("Style", cardStyle)
      let dark = '';
      if (cardStyle == 'dark') {
          dark = true;
      } else {
          dark = false;
      }
      return(
          <>
          {dark ?  <RowContainerFluid>
              {cards.map((section) => (
              <Card dark={cardStyle} content={section.sectionContent} image={section.sectionImageUrl}/>
              ))}
          </RowContainerFluid> :
          <RowContainer>
              {cards.map((section) => (
              <Card dark={cardStyle} content={section.sectionContent} image={section.sectionImageUrl}/>
              ))}
          </RowContainer>
              }
               </>
          )
          };
     
          let busses = [];
          let busAdded = false;
          for (let i = 0; i < sections.length; i++) {
              if (sections[i].sectionLayout === 'bus') {
                     busses.push(sections[i])
                  }
              }
              // const busStyle = busses[0].sectionStyle;
              
          const busFunc = () => {
             
              return(
                  
                  <RowContainer>
                      {busses.map((section) => (
                      <Bus dark={section.sectionStyle} content={section.sectionContent} image={section.sectionImageUrl}/>
                      ))}
                  </RowContainer>
                 
                  )
                  };
  
  console.log("Sections", sections)
  let bgImage = '';
  let output = [];
  let value = '';
  cardsAdded = false;
  for (let i = 0; i < sections.length; i++) {
      if (sections[i].sectionLayout === 'img-right') {
          value = <ImageRight aos='fade-up' dark={sections[i].sectionStyle} content={sections[i].sectionContent} image={sections[i].sectionImageUrl}/>;
          
          output.push(value);
          console.log("right", value)
      }
      if (sections[i].sectionLayout === 'img-left') {
          value = <ImageLeft aos='fade-up' dark={sections[i].sectionStyle} content={sections[i].sectionContent} image={sections[i].sectionImageUrl}/>;
         
          output.push(value);
          console.log("left", value)
      }
      if (sections[i].sectionLayout === 'team-left') {
          value = <Team aos='fade-left' dark={sections[i].sectionStyle} content={sections[i].sectionContent} image={sections[i].sectionImageUrl} direction="left"/>;
         
          output.push(value);
          console.log("left", value)
      }
      if (sections[i].sectionLayout === 'team-right') {
          value = <Team aos='fade-right' dark={sections[i].sectionStyle} content={sections[i].sectionContent} image={sections[i].sectionImageUrl} direction="right"/>;
         
          output.push(value);
          console.log("left", value)
      }
      if (sections[i].sectionLayout === 'card' && cardsAdded == false) {
          value = cardFunc()
          output.push(value)
          cardsAdded = true;
      }
      if (sections[i].sectionLayout === 'bus' && busAdded == false) {
          value = busFunc()
          output.push(value)
          busAdded = true;
      }
      if (sections[i].sectionLayout === 'cta') {
          value = <CTA aos='fade-left' dark={sections[i].sectionStyle} content={sections[i].sectionContent} />
          output.push(value)
      }
      
  }
  console.log("OUTPUt", output)
   return (
   <Layout>
         <FeaturedImage style={style}>
                    <BgImageContainer>
                        <h1>{data.title}</h1>
                        <div style={{background:"RGBA(255,255,255,0.7)", width:"50%", padding:"1em", borderRadius:"10px"}} dangerouslySetInnerHTML={{ __html: data.content }} />
                    </BgImageContainer>

                    <div className="custom-shape-divider-bottom-1667375169">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                             preserveAspectRatio="none">
                            <path d="M598.97 114.72L0 0 0 120 1200 120 1200 0 598.97 114.72z"
                                  className="shape-fill"></path>
                        </svg>
                    </div>
                </FeaturedImage>
                
           {output != '' ?
           output.map((item) => {
               return (item)
           })
           :null}
           <ContainerFluid style={{marginBottom:"0px !important", marginTop:"0px"}}>
              <h2 style={{textAlign:"center"}}>Jetzt anfragen!</h2>
           
           <AnfrageForm style={{textAlign:"left !important"}} />
           </ContainerFluid>
   </Layout>
   )
} 



export const postQuery = graphql`
query($id: String) {
    wpPost(id: {eq: $id}) {
      title
      guid
      id
      content
      contentSections
      featuredImage {
          node {
            sourceUrl
          }
        }
      
  }
}
  
`
export default LandingPage

const FeaturedImage = styled.div`
position:relative;
width: 100vw;
height: 100vh;
min-height:900px;

.custom-shape-divider-bottom-1667375169 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-bottom-1667375169 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 150px;
}

.custom-shape-divider-bottom-1667375169 .shape-fill {
    fill: #FFFFFF;
}

@media (max-width:767px ) {
    .custom-shape-divider-bottom-1667375169 {
        display:none;
    }
}
`
const schema = `{
    {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      'url': 'https://www.richard.de',
      'name': 'Dr. Richard Deutschland',
      'contactPoint': {
        '@type': 'ContactPoint',
        'telephone': ' +49 89 590 68 77 00',
        'contactType': 'Customer Support'
      },
      "location": {
        "@type": "Place",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "München",
          "addressCountry: "Germany",
          "streetAddress": "Bayerstrasse 83",
          "postalCode": "80335"
        },
        "logo": "https://richard.de/static/c97486d1cae0250ec5a8a3e0c475a59d/31451/cropped-logo.webp",
        "service": {
            "@type": "Service",
            "serviceType":"Busreisen"
        }
    }
  }
  `


export const Head = ({data}) => (
  data = data.wpPost,
   <Seo title={data.title} description={data.content} children={schema}/>
  )