import React from "react"
import styled from "styled-components"
import {ContainerFluid, Container} from "../styled/container";
import HTML2React from 'html2react'
const Team = ({content, image, direction}) => {
    console.log("direction", direction);
    return (
        <>
            {direction === 'right' ?
                <Container
               
                >
                     <TeamDiv>
                        <TeamContent>
                        <div dangerouslySetInnerHTML={{ __html: content.slice(1,-1) }} />
                           
                        </TeamContent>
                        <img className="team-member" src={image} width="auto" height="350px" alt=''/>
                    </TeamDiv>
                </Container>
                :
                <ContainerFluid
                
                >
                    <TeamDiv>
                        <img className="team-member" src={image} width="auto" height="350px"  alt=''/>
                        <TeamContent>
                        <div dangerouslySetInnerHTML={{ __html: content.slice(1,-1) }} />
                        </TeamContent>

                    </TeamDiv>
                </ContainerFluid>
            }
        </>
    )
}

export default Team


const TeamContent = styled.div`
display:flex;
flex-direction:column;

`

const TeamDiv = styled.div`
width: 40%;
margin:auto auto;
display:flex;
flex-direction:row;
justify-content: space-between;
align-items: space-between;
gap: 50px;
.team-member {
    border-radius:100%;
}
@media only screen and (max-width: 600px) {
     flex-direction:column;
     width: 100%;
     text-align:center;
}
`
