import React from "react"
import styled from "styled-components"
import {ContainerFluid} from "../styled/container";
import HTML2React from 'html2react'
const LargeImage = ({content, image}) => {
    return (
        <ContainerFluid>
            <LargeImageDiv>
                <LargeImageContent>
                    <h1>LARGE</h1>
                    {HTML2React(content)}
                </LargeImageContent>

            </LargeImageDiv>
            <LargePicture>
                <img className="img-large" src={image} alt='' width="400px" />
            </LargePicture>
        </ContainerFluid>
    )
}

export default LargeImage


const LargeImageContent = styled.div`
display:flex;
flex-direction:column;

`

const LargeImageDiv = styled.div`
width: 80%;
margin:auto auto;
display:flex;
flex-direction:row;
justify-content: space-between;
align-items: space-between;
gap: 50px;
@media only screen and (max-width: 600px) {
     flex-direction:column;
}
`
const LargePicture = styled.div`
z-index: 1;
background:lime;
> & . img-large {
width: 400px !important;
height: auto;

}
`
