import React from "react"
import styled from "styled-components"
import {ContainerFluid, Container} from "../styled/container";
import { Link } from "gatsby"
import { window, document, exists } from 'browser-monads';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
import { device } from '../styled/devices';

const CTA = ({dark, content, aos}) => {
  console.log("CTA", dark)
//   useEffect(() => {
//     AOS.init({duration: 1200,});
// })
    let isDark = true;
    if (dark === 'dark') {
        dark = true;
    } else {
        dark = false;
    }
    return (
        <>

            {dark ? 
            <ContainerFluid>
                <DarkCTA>
                <h4>{dark}</h4>
                    <h4>Sie wollen einen Bus mieten?</h4>
                    <p>Dann füllen Sie jetzt gleich unser Anfrageformular aus - wir senden Ihnen umgehend Ihr persönliches und unverbindliches Angebot für Ihren Reisebus!</p>
                    <Button>
                    <Link to="/bus-mieten/" className="btn from-center">Anfragen</Link>
                    </Button>
                </DarkCTA>
            </ContainerFluid> :
            <Container>
                <LightCTA>
                <h4>Sie wollen einen Bus mieten?</h4>
                    <p>Dann füllen Sie jetzt gleich unser Anfrageformular aus - wir senden Ihnen umgehend Ihr persönliches und unverbindliches Angebot für Ihren Reisebus!</p>
                    <Button>
                    <Link to="/bus-mieten/" className="btn btn-light from-center">Anfragen</Link>
                    </Button>
                </LightCTA>
            </Container>
                }
        </>
    )

}

export default CTA

const DarkCTA = styled.div`
color: white;
width:60%;
margin-left:auto;
margin-right: auto;
padding:2em;
display:flex;
flex-direction:column;
justify-content:center;
align-items: center;
border-radius:10px;

p {
    text-align:center;
}
`

const Button = styled.div`
background-color: transparent;

& > .btn { 
  background-color:#27c223;   
  position: relative; 
  padding: 0.7rem 2.1rem;
  padding-right: 2.1rem;
  font-size: 0.7rem;
  font-weight:bold;
  color: white;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);  
  cursor: pointer;
  user-select: none;
  text-decoration:none;
  color:white;
  text-align:center;
}
& > .btn-light {
    background-color:white;
    color: black;
}
& > .btn:before, .btn:after {
  content: '';
  position: absolute; 
  transition: inherit;
  color:white;
  z-index:3;
}
& > .btn:hover {
  color: #002833;
  transition-delay: .5s;
  
}
& > .btn-light:hover {
    color: black;
    transition-delay: .5s;
}
& > .btn:hover:before {
    box-shadow:1px 1px 10px white;
  transition-delay: 0s;
 
}

& > .btn:hover:after {
  
  background: transparent;
    color: #002833;
  transition-delay: .35s;
}
& > .from-center:before {
  top: 0;
  left: 50%;
  height: 100%;
  width: 0;
  border: 2px solid white;
  border-left:0;
  border-right:0;

}

& > .from-center:after {
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
  background: transparent;

  z-index:2;
}

& > .from-center:hover:before {
  left: 0;
  width: 100%;
  color:white;
 
}

& > .from-center:hover:after {
  top: 0;
  height: 100%;
  border-left: 2px solid white;
  border-right: 2px solid white;
  
}
`


const LightCTA = styled.div`
background-color:#002833;
color: white;
border:5px solid #27c228;
width:60%;
margin-left:auto;
margin-right: auto;
padding:1em;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
p {
    text-align:center;
}
`