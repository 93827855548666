import React from "react"
import styled from "styled-components"
import {ContainerFluid, Container} from "../styled/container";
import HTML2React from 'html2react'
import { device } from '../styled/devices';

const ImageRight = ({content, image, dark}) => {
     if (dark == 'dark') {
        dark = true;
    } else {
        dark = false;
    }
    return (
        <>
            {dark ? <ContainerFluid>
                    <ImageRightDiv>
                        <ImageRightContent>
                        <div dangerouslySetInnerHTML={{ __html: content.slice(1,-1) }} />
                        </ImageRightContent>
                        <ImageRightRightContent>
                        <img src={image} width="100%" height="auto" alt=''/>
                        </ImageRightRightContent>
                    </ImageRightDiv>
                </ContainerFluid>
                :
                <Container>
                    <ImageRightDiv>
                        <ImageRightContent>
                        <div dangerouslySetInnerHTML={{ __html: content.slice(1,-1) }} />
                        </ImageRightContent>
                        <ImageRightRightContent>
                        <img src={image} width="100%" height="auto" alt=''/>
                        </ImageRightRightContent>
                    </ImageRightDiv>
                </Container>
            }
        </>
    )
}

export default ImageRight


const ImageRightRightContent = styled.div`
width: 100%;
@media ${device.laptop} {
    width:50%;
}
`
const ImageRightContent = styled.div`
display:flex;
flex-direction:column;
width: 100%;
@media ${device.laptop} {
    width:49%;
`

const ImageRightDiv = styled.div`
width: 100%;
padding-left:1em;
padding-right:1em;
margin:auto auto;
display:flex;
flex-direction:column;
justify-content: center;
align-items: center;
gap: 50px;
text-align:justify;
@media ${device.laptop} {
    width:80%;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: space-evenly;
}
`

