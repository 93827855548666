import React from "react"
import styled from "styled-components"
import HTML2React from 'html2react'
import { window, document, exists } from 'browser-monads';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
import { device } from '../styled/devices';

const Card = ({content, image, dark}) => {
    // useEffect(() => {
    //     AOS.init({duration: 1200,});
    // })
    console.log("card", dark)
    if (dark == 'dark') {
        dark = true;
    } else {
        dark = false;
    }
    return (
        <>
        {dark ?
        <CardDivDark
        data-sal="zoom-in"
        >
            <img className="card-image" src={image} alt=''/>
            <div className="card-content">
            <div dangerouslySetInnerHTML={{ __html: content.slice(1,-1) }} />

            </div>
        </CardDivDark>
        :
        <CardDiv
        data-sal="zoom-in"
        >
        <img className="card-image" src={image} alt=''/>
        <div className="card-content">
        <div dangerouslySetInnerHTML={{ __html: content.slice(1,-1) }} />

        </div>
    </CardDiv>}
        </>
    )

}

export default Card

const CardDivDark = styled.div`
width:100%;

border-radius:10px;
background-color: #002833;
height: auto;
color:white;

@media ${device.laptop} {
    width:25%;
    margin: 10px;
}
.card-image {
  border-top-right-radius:10px;
  border-top-left-radius:10px;
}
.card-content {
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
    margin-top:-40px;
    
}
`
const CardDiv = styled.div`
width:100%;

border-radius:10px;
background-color: #002833;
height: auto;
color:white;
box-shadow: 1px 2px 5px black;
@media ${device.laptop} {
    width:25%;
    margin: 10px;
}
.card-image {
  border-top-right-radius:10px;
  border-top-left-radius:10px;
}
.card-content {
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
    margin-top:-40px;
    
}
`
