import React, {useEffect} from "react"
import styled from "styled-components"
import {ContainerFluid, Container} from "../styled/container";
import HTML2React from 'html2react'
import { window, document, exists } from 'browser-monads';
// import AOS from 'aos';
import 'aos/dist/aos.css';
import { device } from '../styled/devices';

const ImageLeft= ({content, image, dark, aos}) => {
    // useEffect(() => {
    //     AOS.init({duration: 1200,});
    // })
    if (dark == 'dark') {
        dark = true;
    } else {
        dark = false;
    }
    return (
        <>

            {dark ? <ContainerFluid>
            <ImageLeftDiv
            data-sal="slide-down"
            data-sal-duration="500"
            data-sal-delay="0"
            data-sal-easing="ease"
            >
                <ImageLeftRightContent>
                    <img src={image} width="100%" height="auto" alt=""/>
                </ImageLeftRightContent>
                <ImageLeftContent>
                <div dangerouslySetInnerHTML={{ __html: content.slice(1,-1) }} />
                </ImageLeftContent>
            </ImageLeftDiv>
            </ContainerFluid> :
            <Container>
                <ImageLeftDiv>
                    <ImageLeftRightContent>
                        <img src={image} width="100%" height="auto" alt=""/>
                    </ImageLeftRightContent>
                    <ImageLeftContent>
                    <div dangerouslySetInnerHTML={{ __html: content.slice(1,-1) }} />
                    </ImageLeftContent>
                </ImageLeftDiv>
            </Container>
                }
        </>
    )
}

export default ImageLeft

const ImageLeftDiv = styled.div`
width: 100%;
padding-left: 1em;
padding-right: 1em;
text-align:justify;
display:flex;
flex-direction:column;
justify-content: center;
align-items: center;
p {
    max-width:100%;
}
@media ${device.laptop} {
    width:80%;
    margin:auto auto;
    flex-direction:row;
    justify-content: space-between;
    align-items: space-between;
    gap: 50px;
}
`
const ImageLeftRightContent = styled.div`
width: 50%;

`


const ImageLeftContent = styled.div`
display:flex;
flex-direction:column;
width: 100%;
@media ${device.laptop} {
    width:50%;
}
`
