
import React from "react"
import styled from "styled-components"

const Loading = () => {
    return (
        <Spinner>
            <div className="lds-dual-ring"></div>
        </Spinner>
            )
}
        export default Loading



        const Spinner = styled.div`
        .lds-dual-ring {
        display: inline-block;
        width: 80px;
        height: 80px;
    }
        .lds-dual-ring:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid #002833;
        border-color: #002833 transparent #002833 transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }
        @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
    }
        100% {
        transform: rotate(360deg);
    }
    }

        `

